.single-star-outline {
  height: 36px; 
  width: 31px;
}

.single-star-fill {
  position: relative;
  display: inline-block;
  height: 36px; 
  background-color: #edc774;
}

.single-star-container {
  height: 36px; 
  width: 31px;
  display: inline-block;

}


.stars-container{
  position: relative;
      left:50%;
    transform: translateX(-50%);
    padding-top: 3.5vw;
}


textarea {
  margin-top: 10px;
  margin-left: 50px;
  width: 500px;
  height: 100px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  /*border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;*/
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}


textarea:focus {
    background: none repeat scroll 0 0 #FFFFFF;
    outline-width: 0;
}