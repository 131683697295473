/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
/*@media screen and (min-width: 601px) {
  div.example {
    font-size: 80px;
  }
}*/

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 1300px) {
  div.header-text {
    font-size: 5vh;
  }
  span.header-text {
    text-align: center;
    font-size: 5vh;
  }
  span.header-text-and {
    text-align: center;
    font-size: 5vh;
  }
  span.header-text-and::before {
        content: "\A "; 
        white-space: pre; 
  }
  span.header-text-and::after {
        content: "\A "; 
        white-space: pre; 
  }
  span.past-text{
    font-size: 7vh;
  }
  div.nav-container{
    font-size: 1.5vh;
  }
}



.App {


}
html.with-bg{
  background: url('./test-bg-2.jpg') no-repeat center center fixed;
/*  background-repeat: no-repeat;
  background-size: auto;*/  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.8;
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}*/

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

/*.App-header {
  background-color: rgba(255,255,251,0.7);
  min-height: 100vh;
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}*/

.header-text{
  padding-top: 25%;
  padding-left: 1%;
  font-family: "dm_mono_regular", "Helvetica", sans-serif;
  font-size: 5vw;
  text-align: left;
}

.header-text-and{
  padding-top: 10%;
  padding-left: 1%;
  font-family: "dm_mono_regular", "Helvetica", sans-serif;
  font-size: 5vw;
  text-align: left;
}


.main-container{
  padding-left: 1%;
}

/*.main-container{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  justify-content: center ;
  align-content: center;
}*/
/*.main-item{
  margin: 0 5% 0 5%;
}*/
.past-text{

font-family: "dm_mono_regular", "Helvetica", sans-serif;
    /*font-family: "special_elite_regular", "Helvetica", sans-serif;*/
    
    font-size: 10vw;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    overflow-wrap: break-word;;

}

/*.future-text{

  
  font-family: "dm_mono_regular", "Helvetica", sans-serif;
  padding-top: 15%;
    font-family: "press_start_2p_regular", "Helvetica", sans-serif;
    font-size: 10vw;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
}*/

/*.future-text + p{
    font-family: "press_start_2p_regular", "Helvetica", sans-serif;
    font-size: 0.5vw;
    text-align: center;
    text-decoration: none;
}*/

.nav-container{
  /*margin: 10% 35% 0 35%;*/
  margin: 1% 1% 1% 1%;
  /*margin-top: 1%;*/
  display:flex;
  font-family: "Montserrat","raleway_light","Helvetica", sans-serif;
  font-size: 1vw;
  text-align: center;


}

#nav-bar-list{

  flex-wrap: wrap;
  flex-direction: row; 
  justify-content: space-between ;
}

.content{
  margin-bottom: auto; 
}

.about-text{
  font-family: "raleway_regular","ibm_plex_monomedium","Helvetica", sans-serif;
  padding-top: 4vw;
  padding-left: 4vw;
  font-size: 0.7vw;
}

a { text-decoration: none; color:black; } /* Globally */

/* Each state */

a:visited { text-decoration: none; color:black; }
a:hover { text-decoration: none; color:steelblue; }
a:focus { text-decoration: none; color:#C8C8C8; }
a:hover, a:active { text-decoration: none; color:steelblue }



/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/